import useAuth from "hooks/useAuth";
import EditableProject from "./Project/EditableProject";
import Project from "./Project/Project";
import PriorityLevel from "./PriorityLevel";
import EditablePriorityLevel from "./PriorityLevel/EditablePriorityLevel";
import EditableDueDate from "./DueDate/EditableDueDate";
import DueDate from "./DueDate";
import EditableAssignedTo from "./AssignedTo/EditableAssignedTo";
import AssignedTo from "./AssignedTo";
import AssignToReviewButton from "./AssignedTo/AssignToReviewButton";
import { Modal } from "react-bootstrap";
import ReactQuill from 'react-quill';
import { useState } from "react";
import TaskApi from "apis/TaskApi";

export default function TaskInfo({ task, notesValue, canModify, onUpdate }) {
  const auth = useAuth();
  const [showDescEdit, setShowDescEdit] = useState(false);

  const onOpenEditDescModal = () => {
    setShowDescEdit(true);
    setShowDescEdit(true);
  };

  return (
    <>
      <DescriptionEdit 
        showModal={showDescEdit}
        onHide={() => setShowDescEdit(false)}
        notesValue={notesValue}
        task={task}
        onUpdate={onUpdate}
      />
    
      <div className="py-3 px-4">
        <table className="w-100 table-task_details">
          <tbody>
            <TableRow label="Due Date">
              {task && (
                <>
                  {task.can_modify.due_date ? (
                    <EditableDueDate task={task} onUpdate={onUpdate} />
                  ) : (
                    <DueDate task={task} />
                  )}
                </>
              )}
            </TableRow>
            {/* <TableRow label="Project">
              {task && (
                <>
                  {task.can_modify.project ? (
                    <EditableProject task={task} onUpdate={onUpdate} />
                  ) : (
                    <Project task={task} />
                  )}
                </>
              )}
            </TableRow> */}
            <TableRow label="Priority Level">
              {task && (
                <>
                  {task.can_modify.priority_level ? (
                    <EditablePriorityLevel task={task} onUpdate={onUpdate} />
                  ) : (
                    <PriorityLevel task={task} />
                  )}
                </>
              )}
            </TableRow>
            {(auth && task) && (auth.is_admin || auth.id === task.created_by) && (
              <TableRow label="Created By">
                {task && (
                  <CreatedBy
                    id={task.task_creator_user_id}
                    photo={task.task_creator_user_photo}
                    name={task.task_creator_user_firstname + ' ' + task.task_creator_user_lastname}
                  />
                )}
              </TableRow>
            )}
            <TableRow label="Assigned To">
              {auth && task && (
                <>
                  {auth.is_admin ? (
                    <>
                      {task.employee_task_is_accepted ? (
                        <AssignedTo task={task} />
                      ) : (
                        <EditableAssignedTo task={task} onUpdate={onUpdate} />
                      )}
                    </>
                  ) : (
                    <>
                      {auth.id === task.employee_task_assigned_to && 
                        (task.employee_task_is_accepted === null) ? (
                          <AssignToReviewButton task={task} onUpdate={onUpdate} />
                        ) : (
                          <AssignedTo task={task} />
                      )}
                    </>
                  )}
                </>
              )}
            </TableRow>
            <tr>
              <td colSpan={2}>
                <div className="d-flex  align-items-center mb-3">
                  <p className='text-muted mb-0'>Task Description</p>
                  {task && task.can_modify.note && (
                    <span className="btn btn-outline-primary btn-sm ms-2" tabIndex={0}
                      onClick={onOpenEditDescModal}
                      role="button"
                    >
                      edit
                    </span>
                  )}
                  
                </div>
                  <div className="border rounded p-3 bg-light"
                    dangerouslySetInnerHTML={{ __html: notesValue || '--' }}
                  />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

function DescriptionEdit({ showModal, onHide, notesValue, task, onUpdate }) {
  const [note, setNote] = useState(null);

  const _onUpdate = async () => {
    const { data } = await TaskApi.updateTask(task.employee_task_id, {
      employee_task_notes: note
    });
    if (data && data.success) {
      onUpdate && onUpdate(true);
      onHide();
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={onHide}
      backdropClassName="modal-project-backdrop"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5" id="contained-modal-title-vcenter">
          Edit Task Description
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ReactQuill 
          theme="snow" 
          placeholder='Description'
          defaultValue={notesValue}
          onChange={value => setNote(value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary app fw-bold" onClick={_onUpdate}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  )
}

function TableRow({ label, children }) {
  return (
    <tr>
      <td style={{ verticalAlign: 'top'}} className='text-muted pe-2'>{label}</td>
      <td >
        {children}
      </td>
    </tr>
  );
}

function CreatedBy({ id, name, photo }) {
  return (
    <div className='createdBy-user-view' style={{ '--user-photo': `url(${photo})` }}>
      <div className="photo-user-view" />
      <span>{name}</span>
    </div>
  );
}
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  onGoingTasks: [],
  text: 'App',
}

export const taskSlice = createSlice({
  initialState,
  name: 'task',
  reducers: {
    updateOnGoingTask: (state, action) => {
      if (state.onGoingTasks && state.onGoingTasks.length > 0) {
        const taskIndex = state.onGoingTasks.findIndex(item => item.employee_task_id === action.payload.employee_task_id);
        if (taskIndex === -1) {
          state.onGoingTasks = [...state.onGoingTasks, action.payload];
        } else {
          state.onGoingTasks[taskIndex] = action.payload;
        }
      } else {
        state.onGoingTasks = [...state.onGoingTasks, action.payload];
      }
    },
    updateTitle(state, action) {
      state.text = action.payload;
      console.log(action)
    }
  },
});

export const { updateOnGoingTask, updateTitle } = taskSlice.actions;

export default taskSlice.reducer;
import TaskApi from "apis/TaskApi";
import { setLoading } from "helpers";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import AssignToUsers from 'components/AssignTo';

export default function EditableAssignedTo({ task, onUpdate }) {
  const { 
    assigned_to_user_id: id, 
    assigned_to_user_photo: photo, 
    assigned_to_user_firstname,
    assigned_to_user_lastname, 
    employee_task_is_accepted: isAccepted 
  } = task;
  const [showModal, setShowModal] = useState(false);
  const [assignedTo, setAssignedTo] = useState(null);

  const onChangeAssignedTo = async (userId) => {
    setAssignedTo(userId);
    setLoading(true);
    const { data } = await TaskApi.updateTask(task.employee_task_id, {
      employee_task_assigned_to: userId,
    });
    if (data.success) {
      setShowModal(false);
      setLoading(false);
      toast.success('Successfully Updated. This ticket now is moved to task lists.', {
        position: 'top-center',
      });
      onUpdate && onUpdate(true);
      await TaskApi.requestSuccess({
        employee_task_id: task.employee_task_id,
        request_type: 'employee_task_assigned_to',
        assigned_user_id: userId,
      });
    }
  };

  useEffect(() => {
    if (task) {
      setAssignedTo(id);
    }
  }, [task, id]);

  useEffect(() => {
    if (showModal) {
      document
        .querySelector('.modal-assignedTo-backdrop')
        .style.setProperty('--bs-backdrop-zindex', 1055);
    }
  }, [showModal]);

  return (
    <React.Fragment>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdropClassName="modal-assignedTo-backdrop"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5" id="contained-modal-title-vcenter">
            Assign To
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssignToUsers 
            value={assignedTo}
            onSelected={onChangeAssignedTo}
            exclude_user_id={task && task.created_by}
            employee_task_id={task && task.employee_task_id}
          />
        </Modal.Body>
      </Modal>
      {id ? (
        <div>
          <div 
            className={`assignedTo-user-view AdminViewTaskAssignedTo`} 
            style={{ '--user-photo': `url(${photo})` }}
            role="button"
            onClick={() => setShowModal(true)}
          >
            <div className='d-flex align-items-center w-100'>
              <div className="photo-user-view" />
              <span>
                {assigned_to_user_firstname} {assigned_to_user_lastname}
              </span> 
              {(isAccepted === false && id) && (
                <span className="text-danger small ms-auto"> (Rejected)</span>
              )}
              {(isAccepted === null && id) && (
                <span className="text-warning small ms-auto"> (Pending)</span>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='AdminViewTaskAssignedTo text-muted' 
          onClick={() => setShowModal(true)}
          role="button"
        >
          Not Yet Assigned
        </div>
      )}
    </React.Fragment>
  );
} 
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PriorityText } from 'components';
import { statusColor } from 'helpers';
import useAuth from 'hooks/useAuth';
import Pagination from 'components/Pagination';
import TaskApi from 'apis/TaskApi';
import { useDispatch, useSelector } from 'react-redux';
import { updateEvent } from 'store/trigger';
import { useCallback } from 'react';
import isToday from 'dayjs/plugin/isToday';
import { alert } from 'helpers/alert';
import { updateTriggerList } from 'store/newTask';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';

dayjs.extend(isToday);

function List() {
  const [tasks, setTasks] = useState(null);
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { updatedTask } = useSelector(state => state.trigger);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [noRecords, setNoRecords] = useState(false);
  const [loadingRecord, setLoadingRecord] = useState(false);

  const getTasks = async (params) => {
    const { data } = await TaskApi.paginate({ assigned_task_to: true, ...params });
    if (data.lists && data.lists.length === 0) {
      setNoRecords(true);
    } else {
      setNoRecords(false);
    }
    setTasks(data);
  };

  const onPaginate = (page) => {
    setSearchParams({ page });
    getTasks({ page });
  };

  const onGetTasks = useCallback(async () => {
    const payload = {
      page: searchParams.get('page') || 1,
    };

    const search = searchParams.get('search');
    const due_date_start = searchParams.get('due_date_start');
    const due_date_end = searchParams.get('due_date_end');
    const priority = searchParams.get('priority');
    const status = searchParams.get('status');
    const project_id = searchParams.get('project_id');
    const sort = searchParams.get('sort');

    if (search) payload.search = search;
    if (due_date_start) payload.due_date_start = due_date_start;
    if (due_date_end) payload.due_date_end = due_date_end;
    if (priority) payload.priority = priority;
    if (status) payload.status = status;
    if (project_id) payload.project_id = project_id;
    if (sort) payload.sort = sort;
    
    await getTasks(payload);
  }, [searchParams]);

  const newlyCreated = (task) => {
    if (
      task.read === '0' || (
        auth && 
        task.employee_task_is_accepted === null &&
        auth.id === task.employee_task_assigned_to
      )
    ) {
      return true;
    }
    return false;
  };

  const onSearch = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    setSearchParams({ search: formData.get('search') });
  };

  const onViewTask = async (taskId, index) => {
    const taskData = {...tasks};
    const taskLists = [...taskData.lists];
    taskLists[index].read = '1';
    taskData.lists = taskLists;
    setTimeout(() => setTasks(taskData), 1000);
    navigate('/task/' + taskId);
    if (auth && auth.id === taskLists[index].employee_task_assigned_to) {
      await TaskApi.updateTask(taskId, { read: 1 });
    }
  }

  useEffect(() => {
    (async () => {
      setLoadingRecord(true);
      await onGetTasks();
      setLoadingRecord(false);
    })()
  }, [onGetTasks]);

  return (
    <>      
      <div className="container-fluid">
        <div className="box d-flex flex-column" style={{ minHeight: '700px' }}>
          <div className="d-flex justify-content-between mb-3">
            <h4>{auth && auth.is_admin ? '✔️ Tasks' : '✔️Your Tasks'}</h4>
            {/* <TaskFilter /> */}
            <form className="col-md-3 d-flex" onSubmit={onSearch}>
              <input className='form-control app' placeholder='Search Name' name="search" style={{height: 'auto'}} />
            </form>
          </div>
          <TableWrapper>
            {noRecords && (
              <tr className='empty'>
                <td colSpan="6" className="text-center border-0">
                  No Task added yet.
                </td>
              </tr>
            )}
            {loadingRecord && (
              <tr className='empty'>
                <td colSpan="6" className="text-center border-0">
                  Loading...
                </td>
              </tr>
            )}
            {tasks && tasks.lists && tasks.lists.map((task, key) => (
              <tr 
                key={key} 
                role="button" 
                onClick={() => onViewTask(task.employee_task_id, key)}
                className={`taskListRow ${auth && (auth.id === task.employee_task_assigned_to && task.employee_task_is_accepted === null) ? 'notice' : '' }`}
              >
                <td style={middleTdtyle} className="fw-bold">
                  <div className="d-flex align-items-center" test={JSON.stringify(newlyCreated(task))}>
                    {task.employee_task_name} 
                    {auth && !auth.is_admin && newlyCreated(task) && (
                      <span className="newBadge">New</span>
                    )}
                  </div>
                </td>
                <td style={middleTdtyle}>
                  {task.employee_task_due_date ? <DueDate 
                    dueDate={task.employee_task_due_date}
                  /> : '--'}
                </td>
                <td style={middleTdtyle}>
                  <PriorityText
                    value={task.employee_task_priority_level}
                    allowClick={false}
                  />
                </td>

                <td style={{verticalAlign: 'middle'}}>
                  <AssignedTo task={task} />
                </td>

                <td style={{verticalAlign: 'middle'}}>
                  <CreatedBy task={task} />
                </td>

                <td style={middleTdtyle} align="right">
                  <span 
                    style={{ '--color': statusColor[task && task.employee_task_status] }}
                    className="text-capitalize fw-bold StatusList"
                  >
                    {task.employee_task_status}
                  </span>
                </td>
              </tr>
            ))}
          </TableWrapper>
          <div className="d-flex justify-content-end pt-3 mt-auto border-top">
            <Pagination 
              html={tasks && tasks.pagination}
              onPaginate={onPaginate}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const middleTdtyle={ verticalAlign: 'middle' };

export default List;

function TableWrapper({ children }) {
  const [sortDesc, setSortDesc] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const rotateIconSorter = (sortValue, name) => {
    const _sortValue = [];

    if (sortValue) {
      _sortValue.push(...sortValue.split('-'));
    }

    return (
      <>
        {_sortValue[0] === name && _sortValue[1] === 'desc' ? <BsArrowUp /> : <BsArrowDown />}
        {_sortValue[0] === name && _sortValue[1] === 'desc' ? <BsArrowDown /> : <BsArrowUp />}
      </>
    );
  };

  return (
    <table className="table mb-0 task_list_table mb-3">
      <thead>
        <tr className='headingBg task'>
          <td>Task Name</td>
          <td>
            <span role='button' 
              className='sortTable' 
              onClick={() => {
                setSortDesc(!sortDesc);
                setSearchParams({ sort: sortDesc ? 'due_date-desc' : 'due_date-asc' })
              }}
            >
              Due Date
              <span className='fs-5 ms-1'>
                {rotateIconSorter(searchParams.get('sort'), 'due_date')}
              </span>
            </span>
          </td>
          <td>
            <span role='button' 
              className='sortTable' 
              onClick={() => {
                setSortDesc(!sortDesc);
                setSearchParams({ sort: sortDesc ? 'priority-desc' : 'priority-asc' })
              }}
            >
              Priority
              <span className='fs-5 ms-1'>
              {rotateIconSorter(searchParams.get('sort'), 'priority')}
              </span>
            </span>
          </td>
          <td>Assigned User</td>
          <td>Created By</td>
          <td align="right">
            <span role='button' 
              className='sortTable' 
              onClick={() => {
                setSortDesc(!sortDesc);
                setSearchParams({ sort: sortDesc ? 'status-desc' : 'status-asc' })
              }}
            >
              Status
              <span className='fs-5 ms-1'>
              {rotateIconSorter(searchParams.get('sort'), 'status')}
              </span>
            </span>
          </td>
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  );
}

function CreatedBy({ task }) {
  const auth = useAuth();
  const { 
    task_creator_user_firstname,
    task_creator_user_lastname, 
  } = task;

  if (auth && !auth.is_admin) {
    return <span className='text-muted'>Hidden</span>
  }

  return (
    <div className='d-flex flex-column'>
      <span className='fw-bold'>
        {task_creator_user_firstname} {task_creator_user_lastname}
      </span>
    </div>
  );
}

function DueDate({ dueDate }) {
  if (dayjs(dueDate).isYesterday()) {
    return <span className='fw-bold'>Yesterday</span>;
  }

  if (dayjs(dueDate).isToday()) {
    return <span className='fw-bold'>Today</span>;
  }

  if (dayjs(dueDate).isTomorrow()) {
    return <span className='fw-bold'>Tomorrow</span>;
  }

  return (
    <span className='fw-bold'>
      {dayjs(dueDate).format('DD MMM, YYYY')}
    </span>
  );
}

function AssignedTo({ task }) {
  const auth = useAuth();
  
  const { 
    assigned_to_user_id: id, 
    assigned_to_user_firstname,
    assigned_to_user_lastname, 
    employee_task_is_accepted: isAccepted 
  } = task;

  if (!id) {
    return (
      <div className='assignedTo-user-view text-muted fw-bold'>
        Not Yet Assigned
      </div>
    );
  }

  if (isAccepted === '0' && id) {
    return (
      <div className='assignedTo-user-view fw-bold'>
        <span>
          {assigned_to_user_firstname} {assigned_to_user_lastname} 
        </span> 
        <span className="text-danger small ms-1">(rejected)</span>
      </div>
    );
  }

  if (isAccepted === null && id) {
    return (
      <div className='assignedTo-user-view fw-bold'>
        <span>
          {assigned_to_user_firstname} {assigned_to_user_lastname}
        </span> 
        <span className="text-warning small ms-1">(assigned)</span>
      </div>
    );
  }

  return (
    <div className='assignedTo-user-view'>
      <div className='d-flex flex-column'>
        <span className='fw-bold'>
          {assigned_to_user_firstname} {assigned_to_user_lastname}
        </span>
      </div>
    </div>
  );
}
import { setGlobalLoading } from 'store/loading';
import store from 'store';
import { baseURL } from './http';
import { FaImage } from 'react-icons/fa';
import { BsFilePdf, BsFileZip } from 'react-icons/bs';
import { GrInstall } from 'react-icons/gr';

export function secondsToHms(d) {
  d = Number(d);

  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  return ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
}

export const setLoading = (payload) => {
  store.dispatch( setGlobalLoading(payload) );
}

export const companies = [
  {
    label: 'Hotwork International AG',
    value: 1,
  },
  {
    label: 'Hotwork International Inc. (Philippines)',
    value: 2,
  },
  {
    label: 'XiX-Hotwork',
    value: 3,
  },
  {
    label: 'Hotwork International Inc. (Indonesia)',
    value: 4,
  },
  {
    label: 'Heat Up Latin America',
    value: 5,
  },
  {
    label: 'Hotwork Holding AG',
    value: 6,
  },
  {
    label: 'Hotwork Germany',
    value: 7,
  },
];

export const PENDING = 'pending';
export const COMPLETED = 'completed';
export const IN_PROGRESS = 'in-progress';
export const CLOSED = 'closed';

export const statusColor = {
  [PENDING]: '236, 236, 50',
  [COMPLETED]: '50, 236, 90',
  [IN_PROGRESS]: '255, 176, 75',
  // [CLOSED]: '240, 135, 137',
};

export const priorityColor = {
  low: '53, 167, 203',
  medium: '22, 193, 35',
  high: '243, 196, 63',
  urgent: '243, 75, 75',
};

export const priorityLevels = [
  {
    'color': priorityColor['low'],
    'text': 'Low',
    'value': 'low',
  },
  {
    'color': priorityColor['medium'],
    'text': 'Medium',
    'value': 'medium',
  },
  {
    'color': priorityColor['high'],
    'text': 'High',
    'value': 'high',
  },
  {
    'color': priorityColor['urgent'],
    'text': 'Urgent',
    'value': 'urgent',
  }
];

export const TICKET_RECEIVER = 'joshua.pongasi@hotwork.ag';

const PUSHER_KEY = 'fd536b0e1e784693cd9e';

const pusher = new window.Pusher(PUSHER_KEY, { cluster: 'ap1' });

export const channel = pusher.subscribe('hwi-ticketing');

export const noProfile = baseURL + 'storage/users/user_profile.png';

export const fileTypes = {
  'image/jpg': <FaImage />,
  'image/jpeg': <FaImage />,
  'image/png': <FaImage />,
  'application/pdf': <BsFilePdf />,
  'application/x-zip-compressed': <BsFileZip />,
  'application/x-msdownload': <GrInstall />,
};
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEvent } from 'store/trigger';
import useAuth from './useAuth';

export default function useRealtime(eventName) {
  const trigger = useSelector(state => state.trigger);
  const dispatch = useDispatch();
  const auth = useAuth();

  useEffect(() => {
    if (trigger[eventName] === true) {
      dispatch( 
        updateEvent({ 
          state: eventName, 
          value: false,
          triggered_by: null,
        })
      );
    }
  }, [trigger, eventName, dispatch]);

  if (trigger.triggered_by) {
    if (auth && (auth.id !== trigger.triggered_by && trigger[eventName])) {
      return true;
    }
  }
  return false;
}
import { http } from 'helpers/http';

const base_url = 'ticketing/remarks/';

export default class RemarkApi 
{
  static async paginate(task_id, params = {  }) {
    const resp = await http.get(base_url + 'list/' + task_id, { params });
    return resp;
  } 

  static async create(data) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const resp = await http.post(base_url + 'create_remark', formData);
    return resp;
  }

  static async update(remark_id, data) {
    const resp = await http.post(base_url + 'update_remark/' + remark_id, data);
    return resp;
  }

  static async requestSuccess(data) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const resp = await http.post(base_url + 'request_success', formData);
    return resp;
  }
  
}
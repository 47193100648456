import { forwardRef } from "react";
import { MdClose } from "react-icons/md";

export const TodoItem = forwardRef(({ onDeleteTodo, handleTodoEdit, value }, ref) =>  {
  return (
    <li className="droppable d-flex justify-content-between align-items-center"
      ref={ref}
    >
      <span draggable="true" className='d-flex' style={{ width: '95%' }}>
        <input className="form-control" placeholder='Name' 
          value={value} 
          onChange={handleTodoEdit}
        />
      </span>
      <button title="Delete" onClick={onDeleteTodo}>
        <MdClose />
      </button>
    </li>
  );
});
import { priorityColor } from "helpers";

export default function PriorityLevel({ task }) {
  return (
    <>
      <div 
        className={`user-view_PriortyLevel creatorView text-uppercase`} 
        style={{ '--color': priorityColor[task.employee_task_priority_level] }}
      >
        {task.employee_task_priority_level || <span className="text-muted fw-light text-capitalize">No priority</span>}
      </div>
    </>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import task from './task';
import trigger from './trigger';
import loading from './loading';
import newTask from './newTask';

 const store = configureStore({
  reducer: {
    task,
    trigger,
    loading,
    newTask
  }
});

export default store;
import Header from './Header';
import Sidebar from './Sidebar';
import { Outlet } from "react-router-dom";
import './layout.css';

function Layout() {
  return (
    <>
      <div className='notification'>
        Test
      </div>
      <div className="App">
        <Sidebar />
        <div className="page-manager">
          <Header />
          <div className="content">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
import { useEffect } from "react";
import { createPortal } from "react-dom";

function Loading() {
  return (
    <>
      <div className='loading-indicator' title="Loading... Please wait..."></div>
      <div className="text-loading">Loading...</div>
    </>
  );
}

const _loadingEl = document.getElementById('_loading');

function PortalLoading() {
  const div = document.createElement('div');

  useEffect(() => {
    _loadingEl.appendChild(div);
    return () => {
      _loadingEl.removeChild(div)
    }
  }, []);

  return createPortal(
    <Loading />,
    _loadingEl
  );
}

export default PortalLoading;
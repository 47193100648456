import TaskApi from "apis/TaskApi";
import { CLOSED, COMPLETED, IN_PROGRESS, PENDING, setLoading, statusColor } from "helpers";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { MdOutlineModeEditOutline } from "react-icons/md";

export default function EditableStatus({ task, onUpdate }) {
  const [showModal, setShowModal] = useState(false);

  const inputProps = (status) => {
    return {
      className: `StatusButton text-uppercase text-muted ${status === task.employee_task_status ? 'active': ''}`,
      style: {'--color': statusColor[status]},
      onClick: () => onClickStatus(status),
      role: "button"
    }
  };

  const onClickStatus = async (status) => {
    if (!task.employee_task_assigned_to) {
      toast.error('Please assign this task to someone before changing the status.', {
        position: 'top-center',
      });
      return;
    }
    if (task.employee_task_assigned_to && task.employee_task_is_accepted === false) {
      toast.error('Task has been declined. Please assign it to someone before changing the status.', {
        position: 'top-center',
      });
      return;
    }
    if (status === task.employee_task_status) {
      setShowModal(false);
      return;
    }
    setLoading(true);
    const { data } = await TaskApi.updateTask(task.employee_task_id, { 
      employee_task_status: status 
    });
    if (data.success) {
      setLoading(false);
      toast.success('👍 Status successfully updated.');
      setShowModal(false);
      onUpdate && onUpdate(true);
      await TaskApi.requestSuccess({
        employee_task_status: status,
        request_type: 'employee_task_status',
        employee_task_id: task.employee_task_id,
      });
    }
  };

  useEffect(() => {
    if (showModal) {
      document
        .querySelector('.modal-task_status-backdrop')
        .style.setProperty('--bs-backdrop-zindex', 1055);
    }
  }, [showModal]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdropClassName="modal-task_status-backdrop"
        centered
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5" id="contained-modal-title-vcenter">
            Change Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div {...inputProps(PENDING)}>
            {PENDING}
          </div>
          <div {...inputProps(IN_PROGRESS)}>
            {IN_PROGRESS}
          </div>
          <div {...inputProps(COMPLETED)}>
            {COMPLETED}
          </div>
          {/* <div {...inputProps(CLOSED)}>
            {CLOSED}
          </div> */}
        </Modal.Body>
      </Modal>
      <div 
        className='task_StatusAdmin mt-2 text-capitalize d-flex align-items-center text-muted' 
        style={{ '--color': statusColor[task.employee_task_status] }}
        role="button"
        onClick={() => setShowModal(true)}
      >
        {task.employee_task_status}
        <MdOutlineModeEditOutline className='ms-auto' />
      </div>
    </>
  );
}
import TaskApi from "apis/TaskApi";
import dayjs from "dayjs";
import { setLoading } from "helpers";
import { forwardRef, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-hot-toast";

export default function EditableDueDate({ task, onUpdate }) {
  const [date, setDate] = useState(null);

  const onChange = async (employee_task_due_date, ev) => {
    setDate(employee_task_due_date);
    setLoading(true);
    const { data } = await TaskApi.updateTask(task.employee_task_id, {
      employee_task_due_date: employee_task_due_date ? dayjs(employee_task_due_date).format('YYYY-MM-DD') : null 
    });
    if (data.success) {
      setLoading(false);
      toast.success('👍 Successfully Updated.', {
        position: 'top-center'
      });
      onUpdate && onUpdate(true);
      await TaskApi.requestSuccess({
        request_type: 'employee_task_due_date',
        employee_task_due_date: dayjs(employee_task_due_date).format('YYYY-MM-DD'),
        employee_task_id: task.employee_task_id,
      });
    }
  };

  useEffect(() => {
    if (task) {
      setDate(task.employee_task_due_date ? new Date(task.employee_task_due_date) : new Date());
    }
  }, [task]);

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => {
    const dueToday = dayjs(value).isToday();
    const dueYesterday = dayjs(value).isYesterday();
    const dueTomorrow = dayjs(value).isTomorrow();

    if (task && !task.employee_task_due_date) {
      return (
        <span 
          className={`ViewCreatorTicketDueDate text-muted canEdit`} 
          role="button"
          onClick={onClick}
          ref={ref}
        >
          No Due Date
        </span>
      );
    }

    return (
      <span 
        className={`ViewCreatorTicketDueDate canEdit`} 
        role="button"
        onClick={onClick}
        ref={ref}
      >
        {(dueYesterday || dueToday || dueTomorrow) ? (
          <>
            {dueYesterday && 'Yesterday'}
            {dueToday && 'Today'}
            {dueTomorrow && 'Tomorrow'}
          </>
        ) : (
          <>
            {dayjs(value).format('DD MMM, YYYY')}
          </>
        )}
        
      </span>
    );
  });

  return (
    <ReactDatePicker 
      minDate={new Date()}
      selected={date}
      onChange={(date, ev) => onChange(date, ev)}
      isClearable={task && (task.employee_task_due_date || false)}
      customInput={<DateCustomInput />}
    />
  );
}
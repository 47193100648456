import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Task from 'Pages/Task';
import Tickets from 'Pages/Tickets';
import Layout from './Layout'
import Project from 'Pages/Project';
import Dashboard from 'Pages/Dashboard';
import { Provider, useSelector } from 'react-redux';
import store from './store';
import Login from 'Pages/Login';
import PortalLoading from 'components/Loading';
import useAuth from 'hooks/useAuth';
import NotFound from 'components/404';
import NewTicket from 'Pages/NewTicket';
import login from 'helpers/login';

// store.subscribe(() => {
//   console.log(store.getState());
// });

function App() {
  const auth = useAuth();

  login();

  useEffect(() => {
    if (auth && auth.firstname && auth.lastname) {
      document.title = 'Helpdesk Taskforce | ' + auth.firstname + ' ' + auth.lastname;
    } else {
      document.title = 'Helpdesk Taskforce';
    }
  }, [auth]);

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/login" element={<Login />} />
            <Route path="/"  element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="task/*" element={<Task />} />
              <Route path="ticket/*" element={<Tickets />} />
              <Route path="project/*" element={<Project />} />
              <Route path="new-ticket/*" element={<NewTicket />} />
              <Route path="dashboard" element={<Dashboard />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <Loader />
      </Provider>
    </>
  );
}

function Loader() {
  const { globalLoading } = useSelector(state => state.loading);
  if (globalLoading) return <PortalLoading />;
  return <></>;
}

export default App;

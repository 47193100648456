import TaskApi from 'apis/TaskApi';
import dayjs from 'dayjs';
import { CLOSED } from 'helpers';
import React, { createRef, useEffect, useState, useCallback } from 'react';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateEvent } from 'store/trigger';
import './viewTaskFinal.css';
import { MdOutlineInsertDriveFile } from 'react-icons/md';
import useAuth from 'hooks/useAuth';
import _ from 'lodash';
import NotFound from 'components/404';
import Todos from './Todos';
import Remarks from './Remarks';
import TaskInfo from './TaskInfo';
import Status from './Status';
import EditableStatus from './Status/EditableStatus';
import Title, { EditableTaskTitle } from './Title';
import {  BsPlusLg } from 'react-icons/bs';
import useRealtime from 'hooks/useRealtime';

dayjs.extend(updateLocale);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);
dayjs.extend(relativeTime);

dayjs.updateLocale('en', {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: 'a few sec',
    m: "a min",
    mm: "%dmin",
    h: "anh",
    hh: "%dh",
    d: "a day",
    dd: "%ddays",
    M: "am",
    MM: "%dm",
    y: "ay",
    yy: "%dy"
  }
})

export default function ViewCreatorTicket({ ...props }) {
  const [todos, setTodos] = useState(null);
  const [notesValue, setNotesValue] = useState(null);
  const { taskId } = useParams();
  const [task, setTask] = useState(null);
  const { updated_task } = useSelector(state => state.trigger);
  const dispatch = useDispatch();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const isRLupdated = useRealtime('updated_task');

  const getTask = useCallback(async () => {
    if (taskId) {
      const { data } = await TaskApi.get(taskId);
      setTask(data);
    }
  }, [taskId]);

  const canModify = () => {

    const canUpdate = {
      taskTitle: false,
      taskStatus: false,
      project: false,
      priorityLevel: false,
      dueDate: false,
      changeAssignedTo: false,
      acceptAssignedTo: false,
      addTodo: false,
      checkTodo: false,
      addRemark: false,
    };

    if (task) {
      if (
        auth && 
        (auth.id === task.created_by && task.employee_task_status !== CLOSED)
      ) {
        canUpdate.taskTitle = true;
      }

      if (
        auth && 
        auth.is_admin
      ) {
        canUpdate.taskStatus = true;
      }

      if (
        auth && 
        (auth.is_admin || auth.id === task.created_by)
      ) {
        canUpdate.project = true;
      }

      if (
        auth && 
        (auth.is_admin || auth.id === task.created_by)
      ) {
        canUpdate.priorityLevel = true;
      }

      if (
        auth && 
        task.employee_task_assigned_to === null &&
        (task.employee_task_is_accepted === '0' || task.employee_task_is_accepted === null)
      ) {
        canUpdate.dueDate = true;
      }

      if (
        auth &&
        (auth.is_admin && task.employee_task_assigned_to === null) &&
        (task.employee_task_is_accepted === '0' || task.employee_task_is_accepted === null)
      ) {
        canUpdate.changeAssignedTo = true;
      }

      if (
        auth &&
        task.employee_task_assigned_to === auth.id &&
        task.employee_task_is_accepted === '0'
      ) {
        canUpdate.acceptAssignedTo = true;
      }

      if (
        auth && 
        task.employee_task_status !== CLOSED &&
        task.created_by === auth.id
      ) {
        canUpdate.addTodo = true;
      }
      
      if (
        auth && 
        task.employee_task_status !== CLOSED &&
        task.employee_task_assigned_to === auth.id &&
        task.employee_task_is_accepted === true
      ) {
        canUpdate.checkTodo = true;
      }

      if (task.employee_task_status !== CLOSED) {
        canUpdate.addRemark = true;
      }
    }
    return canUpdate;
  }

  const newTodo = (todo) => {
    if (Array.isArray(todos)) {
      setTodos(todos => ([
        ...todos, 
        { ...todo, nodeRef: createRef(null) }
      ]));
    }
  };

  const onUpdate = (isUpdate) => {
    isUpdate && getTask();
    setIsUpdated(isUpdate);
    setTimeout(() => {
      setIsUpdated(false);
    }, 100);
  };

  useEffect(() => {
    if (isRLupdated) {
      getTask();
    }
  }, [isRLupdated]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getTask();
      setLoading(false);
    })();
  }, [getTask]);

  useEffect(() => {
    if (task) setNotesValue(task.employee_task_notes);
    return () => {
      setNotesValue(null);
    }
  }, [task]);

  useEffect(() => {
    if (task) {
      setTodos(
        task.todos
          ?.map(item => ({
            ...item,
            nodeRef: createRef(null)
          }))
      );
    }
  }, [task]);

  if (task && typeof task === 'string') {
    return (
      <NotFound />
    );
  }

  return (
    <div className="container-fluid">
      <div 
        className={`bg-white px-3 py-3 rounded position-relative ${loading ? 'maskViewerTask' : ''}`} 
        style={{ minHeight: '800px' }}
      >
        <div className="d-flex border-bottom pb-3 w-100">
          <div className='d-flex flex-column w-100'>
          {task && (
            <>
              {canModify().taskTitle ? (
                <span className='mb-2'>
                  <EditableTaskTitle task={task} />
                </span>
              ) : (
                <Title task={task} />
              )}

              {canModify().taskStatus ? (
                <EditableStatus task={task} onUpdate={onUpdate} />
              ) : (
                <Status task={task} />
              )}
            </>
          )}
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-4 p-0">
              <TaskInfo 
                task={task} 
                notesValue={notesValue}
                onNotesChange={(value) => setNotesValue(value)}
                canModify={canModify()}
                onUpdate={onUpdate}
              />
              {task && task.attachments.length > 0 && (
                <div className="py-3 px-4">
                  <div className="d-flex justify-content-between">
                    Attachments
                  </div>
                  {task.attachments.map((attch, key) => (
                    <a key={key} className="attached_files"
                      target="_blank"
                      href={`https://hotware.blob.core.windows.net/apps/Hotware/ticketing/${attch.filename}`}
                    >
                      <span className="fileLogo"><MdOutlineInsertDriveFile /></span>
                      <span>{attch.filename}</span>
                    </a>
                  ))}
                </div>
              )}
            </div>
            {task && (
              <>
                <div className="col-md-4  border-start">
                  <div className="h-100 d-flex flex-column">
                    <Todos
                      task_id={task && task.employee_task_id}
                      todos={todos}
                      canModify={canModify()}
                      task={task}
                      newTodo={newTodo}
                      onUpdate={onUpdate}
                    />
                  </div>
                </div>
                <div className="col-md-4 border-start p-0">
                  {task && task.can_modify.can_view_remarks && (
                    <div className="remarks-content">
                      <Remarks 
                        task_id={task.employee_task_id}
                        task={task}
                        canModify={canModify()}
                        isUpdated={isUpdated}
                        onUpdate={onUpdate}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
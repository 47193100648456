import { http } from 'helpers/http';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AiFillBell, AiOutlineBell } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import './notification.css';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { useCallback } from 'react';
import CreateTicket from 'components/CreateTicket';
import { IoChevronBackSharp, IoChevronForwardSharp } from 'react-icons/io5';
import { setLoading } from 'helpers';
import useRealtime from 'hooks/useRealtime';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export function NotificationContent({openNotif, onUpdated}) {
  const [notifications, setNotifications] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(1);
  const auth = useAuth();
  const navigate = useNavigate();

  const onScrollTriggerPagination = (e) => {
    const div = document.createElement('div');
    div.innerHTML = pagination;
    
    if (Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 1) {
      if (div.querySelector('.active a')) {
        setPage(
          parseInt(div.querySelector('.active a').innerText) + 1
        );
      }
    }
  }

  useEffect(() => {
    (async () => {
      const { data } = await http.get('ticketing/notification', { params: { page } });
      if (data && Array.isArray(data.lists) && data.lists.length > 0) {
        const _notifications = notifications ? [...notifications] : [];
        _notifications.push(...data.lists);
        setNotifications(_notifications);
        setPagination(data.pagination !== '' ? data.pagination : null);
      } else {
        setNotifications([]);
      }
    })();
  }, [page]);

  return (
    <div className="notification-list-menu py-0 d-flex flex-column" onScroll={onScrollTriggerPagination}>
      <p 
        className='p-3 py-2 text-muted mb-1 mt-0 border-bottom fw-bold'
        style={{ position: 'sticky', top: 0, background: '#fff', zIndex: 1, fontSize: '1.5em' }}
      >
        Activity Logs
      </p>

      {notifications === null && <p className="py-3 text-center text-muted">Loading...</p>}

      {(Array.isArray(notifications) && notifications.length === 0) && (
        <p className="py-3 text-center  text-muted">No Records Found.</p>
      )}

      {notifications && notifications.map((item, key) => (
        <a 
          key={key} 
          role="button"
          className={`d-flex p-3 notification_item dropdown-item position-relative dropdown-item border-bottom`}
          href={'/ticket/' + item.ref_id}
          onClick={(e) => {
            e.preventDefault();
            navigate('/ticket/' + item.ref_id);
          }}
        >
          <div className="notif-avatar text-uppercase"
            style={{ '--bg-color': item.task_color }}
          >
            {(item.employee_task_name || '').replace(/\s/g, '').slice(0, 1)}
          </div>
          <div>
            <div className='notif-name'>{item.employee_task_name || ''}</div>
            <div className='notif-message text-muted'>{item.message}</div>
            <CreatedAt date={item.created_at} />
          </div>

          {/* {item.user_read === '0' && (
            <div className="unopen-indicator"
              style={{ '--dot-color': item.user_read === '0' ? '#0d6efd' : null }}
            />
          )} */}
        </a>
      ))}

      {/* {pagination && (
        <div 
          className='p-2 text-muted mb-1 mt-0 d-flex justify-content-end border-top mt-auto'
          style={{ position: 'sticky', bottom: 0, background: '#fff' }}
        >
          <Pagination html={pagination} onPaginate={onPaginate} />
        </div>
      )} */}
    </div>
  );
}

function Header() {
  const [ticketModal, setTicketModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <CreateTicket 
        modalShow={ticketModal}
        onHideModal={() => setTicketModal(false)}
      />
      <header className="App-header">
        <div className="logo-container">
          <img src={require('images/hwi_logo.png')} alt="Hotware Logo" />
          <h5 className="mb-0 text-muted ms-2 fw-bold">Helpdesk Taskforce</h5>
        </div>
        <div className="Header-Right ms-auto d-flex align-items-center">
          {/* <div className='back_forward'>
            <button type="button" className="btn btn-light app fw-bold"
              onClick={() => navigate(-1)}
            >
              <IoChevronBackSharp />
            </button>
            <button type="button" className="btn btn-light app fw-bold"
              onClick={() => navigate(1)}
            >
              <IoChevronForwardSharp />
            </button>
          </div> */}

          {/* {auth && !auth.is_admin && ( */}
            <button type="button" className="btn btn-primary app fw-bold"
              onClick={() => setTicketModal(true)}
            >
              Create Ticket
            </button>
          {/* )} */}
          
          <div className='profile-content ms-auto d-flex align-items-center'>
            {/* <Notification /> */}
            <Profile />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;

function Profile() {
  const auth = useAuth();

  const onLogout = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(true);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.dispatchEvent(new Event('storage'));
      setLoading(false);
    }, 1000);
  };

  return (
    <Dropdown className='profile-wrapper'>
      <Dropdown.Toggle variant='btn'>
        <span className='AuthPhoto' style={{ background: `url(${auth && auth.photo})` }} />
        <span className="AuthName me-2">{auth && auth.firstname}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <button className="dropdown-item"
          onClick={onLogout}
        >
          Logout
        </button>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function Notification() {
  const [showContent, setShowContent] = useState(false);
  const [count, setCount] = useState(null);
  const isRLUpdated = useRealtime('updated_notifs');

  const notifyCount = useCallback(async () => {
    const { data } = await http.get('ticketing/notification/count');
    const _count = Number(data);
    if (_count > 9) {
      setCount('9+');
    } else {
      setCount(_count !== 0 ? _count : '');
    }
  }, []);

  const onUpdated = (isUpdate) => {
    if (typeof count === 'number') {
      const _count = count;
      if (_count === 1) {
        setCount(null);
      } else {
        setCount(_count - 1);
      }
    }
  };

  useEffect(() => {
    notifyCount();
  }, [notifyCount]);

  useEffect(() => {
    if (isRLUpdated) {
      notifyCount();
    }
  }, [isRLUpdated]);

  return (
    <Dropdown 
      align="end"
      onToggle={(show) => setShowContent(show)}
    >
      <Dropdown.Toggle 
        variant='btn' 
        className='notification-bell text-muted p-0'
        role="button" 
        data-count={count}
      >
        {showContent ? <AiFillBell /> : <AiOutlineBell />}
      </Dropdown.Toggle>
      {showContent && <NotificationContent onUpdated={onUpdated} />}
    </Dropdown>
  );
}



function CreatedAt({ date }) {

  if (dayjs().diff(date, 'year') > 0) {
    return (
      <div className='notif-created-at text-muted'>
        {dayjs.utc(date).format('MMM DD, YYYY')}
      </div>
    );
  }

  if (dayjs().diff(date, 'day') > 0) {
    return (
      <div className='notif-created-at text-muted'>
        {dayjs.utc(date).format('MMM DD')}
      </div>
    );
  }

  return (
    <div className='notif-created-at text-muted'>
      {dayjs.utc(date).fromNow()}
    </div>
  );
}
import './CreateTicket.css';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import React from 'react';
import { MdOutlineAttachment } from 'react-icons/md';
import { FaTimes } from 'react-icons/fa';
import { setLoading } from 'helpers';
import TaskApi from 'apis/TaskApi';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import Todos from './Todos';
import { useDispatch } from 'react-redux';
import { updateTriggerList } from 'store/newTask';

export default function CreateTicket({ modalShow, onHideModal }) {
  const [employee_task_name, setTaskName] = useState(null);
  const [employee_task_due_date, setDueDate] = useState(null);
  const [employee_task_notes, setNotes] = useState(null);
  const [todos, setTodos] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const dispatch = useDispatch();

  const onChangeAttachment = (e) => {
    const files = [];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push({
        file: e.target.files[i],
        name: e.target.files[i].name
      });
    }
    setSelectedFiles(files);
  };

  const onDeleteSelectedFile = (index) => {
    const files = [...selectedFiles].filter((item, key) => key !== index);
    setSelectedFiles(files);
  };

  const onSubmit = async () => {
    setLoading(true);
    
    const payload = { 
      employee_task_name,
      employee_task_notes: employee_task_notes || '',
      todos: JSON.stringify(todos.map(item => ({ employee_task_todo_name: item.name }))),
    };

    if (employee_task_due_date) {
      payload['employee_task_due_date'] = dayjs(employee_task_due_date).format('YYYY-MM-DD')
    }

    if (selectedFiles) {
      selectedFiles.forEach((item, key) => {
        payload[`attached_files[${key}]`] = item.file
      });
    }
    const { data } = await TaskApi.createTask(payload);
    if (data.success) {
      toast.success('Ticket added successfully.', {
        position: 'top-center'
      });
      onHideModal && onHideModal();
      setTaskName(null);
      setDueDate(null);
      setNotes(null);
      setTodos([]);
      setLoading(false);
      setSelectedFiles(null);
      dispatch( updateTriggerList(true) );
      // dispatch( updateTriggerList(false) );
      await TaskApi.requestSuccess({
        request_type: 'create_task',
        employee_task_id: data.employee_task_id,
      });
    }
  };

  const onCancel = () => {
    onHideModal && onHideModal();
    setTaskName(null);
    setDueDate(null);
    setNotes(null);
    setTodos([]);
  }

  const disabledButton = () => {
    if (!employee_task_name || employee_task_name === '') return true;
    return false;
  }

  return (
    <Modal 
      show={modalShow} 
      onHide={() => onCancel()} 
      centered
      size="xl"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">Create Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body className='py-0'>
        <div className="row">
          <div className="col-md-6">
            <div className="py-3">
              <div className="mb-4">
                <label className="mb-2">Name</label>
                <input className="form-control" placeholder="Name" 
                  value={employee_task_name || ''}
                  onChange={(e) => setTaskName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="mb-2">Due Date (optional)</label>
                <ReactDatePicker 
                  className="form-control"
                  selected={employee_task_due_date} 
                  onChange={(date) => setDueDate(date)}
                  placeholderText="Due Date"
                  minDate={new Date()}
                  calendarClassName="hwi_calendar"
                />
              </div>
              
              <div>
                <label className='mb-2'>Notes (optional)</label>
                <ReactQuill 
                  theme="snow" 
                  placeholder='Notes'
                  value={employee_task_notes || ''}
                  onChange={value => setNotes(value)}
                />
              </div>

              <div>
                <label htmlFor="attachment" className="d-flex align-items-center attachmentButton">
                  <MdOutlineAttachment />
                  <span className='ms-2'>{selectedFiles && selectedFiles.length} Attachment</span>
                  <input type="file" id="attachment" className="d-none" multiple={true} 
                    onChange={onChangeAttachment}
                  />
                </label>
              </div>

              {selectedFiles && (
                <div className='files-selected'>
                  {selectedFiles && selectedFiles.map((item, key) => (
                    <div key={key}>
                      <span>{item.name}</span>
                      <button onClick={() => onDeleteSelectedFile(key)}>
                        <FaTimes />
                      </button>
                    </div>
                  ))}
                </div>
              )}

            </div>
          </div>
          <div className="col-md-6 border-start">
            <div className="py-3">
              <label className='mb-2'>Todos (optional)</label>
              <Todos 
                todos={todos}
                onChangeTodos={todos => setTodos(todos)}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-light fw-bold" onClick={onCancel}>Cancel</button>
        <button 
          className="btn btn-primary app fw-bold" 
          disabled={disabledButton()}
          onClick={onSubmit}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  )
}

import { http } from 'helpers/http';

export default class ProjectApi 
{

  static async paginate(params = {}) {
    const res = await http.get('ticketing/project', { params });
    return res;
  }

  static async create(data) {
    const formData = new FormData();

    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    const res = await http.post('ticketing/project/create', formData);
    return res;
  }
}
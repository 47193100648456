import { statusColor } from "helpers";

export default function Status({ task }) {
  return (
    <div 
      className='task_Status text-capitalize text-muted' 
      style={{ '--color': statusColor[task && task.employee_task_status], width: 'fit-content' }}
    >
      {task && task.employee_task_status}
    </div>
  )
}
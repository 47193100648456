import { noProfile } from "helpers";
import useAuth from "hooks/useAuth";


export default function RemarkList({ name, photo, message, task, createdBy, is_admin, attachments, remark_type }) {
  const auth = useAuth();

  const username = () => {
    if (auth) {
      if (auth.is_admin) return name;
      if (auth.id == createdBy) return name;
      return 'Admin';
    }
  };

  return (
    <div className="remarkContentComment px-3 flex-column">
      <div className="d-flex">
        <div className="remarkContentCommentProfile"
          style={{ 
            backgroundImage: auth && auth.is_admin ? `url(${photo})` : `url(${noProfile})` 
          }} 
        />
        <div className="remarkContentInfo">
          <div className='d-flex'>
            <div className="remarkContentUserName me-2">
              <div className='d-flex flex-column text-muted'>
                {username()}
              </div>
              {remark_type === 'reject_task' && (
                <span className="small" style={{color: '#fc5b5b'}}>
                  rejected a task
                </span>
              )}
            </div>
          </div>
          {message && (
            <div className="remarkContentMessage">
              {message || ''}
            </div>
          )} 
        </div>
      </div>
      {attachments && attachments.length > 0 && (
        <div className="attachmentFiles">
          {attachments && attachments.map((item, key) => (
            <a className="attchedFiles" key={key}
              href={`https://hotware.blob.core.windows.net/apps/Hotware/ticketing/${item.filename}`}
              target="_blank"
            >
              {item.filename}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}
import { forwardRef } from 'react';
import './component.css';
import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import {priorityLevels} from 'helpers';
import Dropdown from 'react-bootstrap/Dropdown';
import isToday from 'dayjs/plugin/isToday';

export { default as ColorDropdown } from './ColorDropdown';
export { default as TableAction } from './TableAction';

dayjs.extend(isToday);

export function PriorityText({ value, onChange, allowClick = true }) {
  if (!value) return '--';

  const priority = priorityLevels.find(item => item.value === value);

  return (
    <Dropdown align="start" className="priority-levels">
      <div className="dropdown-toggle-app fw-bold" style={{ '--color': priority?.color }}>
        {priority?.text}
      </div>
    </Dropdown>
  );
}

export function DueDate({ value, onChange, placeholderText, canUpdate = true }) {

  const dateValue = (value) => {
    if (value) {
      return dayjs(value).isToday() ? 'Today' : dayjs(value).format('DD MMM, YYYY');
    }
    return '';
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="task_due_date" onClick={onClick} ref={ref}>
      {dateValue(value)}
    </button>
  ));

  if (!canUpdate) {
    return dateValue(value);
  }

  return (
    <ReactDatePicker
      onChange={onChange}
      dateFormat="MM-dd-yyyy"
      minDate={new Date()}
      selected={new Date(value)}
      customInput={<ExampleCustomInput />}
      placeholderText={placeholderText}
      calendarClassName="hwi_calendar"
    />
  );
}
import { http } from 'helpers/http';
import React, { useCallback, useEffect, useState } from 'react';
import { IoCheckmarkSharp } from 'react-icons/io5';
import './assign-to.css';
import _ from 'lodash';

const AssignTo = React.forwardRef(({ onSelected, value, exclude_user_id, returnType = null, employee_task_id }, ref) => {
  const [users, setUsers] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(1);

  const selectedId = (
    users?.find(item => item.user_id === value)?.user_id
  );

  const onSelectUser = (user_id) => {
    if (returnType === 'object') {
      const user = users?.find(item => item.user_id === user_id);
      onSelected && onSelected(user);
    } else {
      onSelected && onSelected(user_id);
    }
  };

  const fetchUser = _.debounce(async (_search) => {
    const params = { search: _search, exclude_user_id, employee_task_id };
    const { data } = await http.get(`mobile/user/get`, { params });
    if (data) {
      setUsers(data.list);
      setPagination(data.pagination);
    }
  }, 1000)

  const updateTaskName = useCallback((name) => fetchUser(name), []);

  const onSearchUser = (e) => updateTaskName(e.target.value);

  const onScrollTriggerPagination = (e) => {
    const div = document.createElement('div');
    div.innerHTML = pagination;
    
    if (Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 1) {
      setPage(
        parseInt(div.querySelector('.active a').innerText) + 1
      );
    }
  }

  useEffect(() => {
    (async () => {
      const params = { page, exclude_user_id, employee_task_id };
      const { data } = await http.get(`mobile/user/get`, { params });
      if (data && Array.isArray(data.list) && data.list.length > 0) {
        const _list = users ? [...users] : [];
        _list.push(...data.list);
        setUsers(_list);  
        setPagination(data.pagination);
      }
    })();
  }, [page, exclude_user_id]);

  return (
    <div ref={ref}>
      <div>
        <div className="mb-2">
          <input placeholder="Search" className="form-control"
            onChange={onSearchUser}
          />
        </div>
      </div>
      <div style={{ height: pagination ? '317px' : '364px' }} 
        className={`overflow-auto scrolled-content 
          ${users && (users.length < 1 || !users) ? 'd-flex align-items-center justify-content-center' : ''}`}
        onScroll={onScrollTriggerPagination}
      >
        {users && users.map((user, key) => (
          <List 
            key={key}
            user_id={user.user_id}
            image={user.user_photo}
            name={user.user_firstname + ' ' + user.user_lastname}
            selectedId={selectedId}
            onCheck={onSelectUser}
          />
        ))}
        {users && users.length < 1 && (
          <span className='text-muted'>No Users found.</span>
        )}

        {!users && (
          <span className='text-muted'>Loading...</span>
        )}
      </div>
      {/* <Pagination
        className="d-flex justify-content-center border-top pt-2" 
        html={pagination}
        onPaginate={(page) => setPage(page)}
      /> */}
    </div>
  );
});

export default AssignTo;

function List({ user_id, image, name, selectedId, onCheck }) {
  return (
    <div className={`user-list d-flex justify-content-between align-items-center rounded py-2 px-3 ${selectedId === user_id ? 'active' : ''}`} 
      role="button"
      onClick={() => selectedId !== user_id && onCheck && onCheck(user_id)}
    >
      <div className='d-flex align-items-center'> 
        <span className="image me-2 position-relative" style={{ background: `url(${image})` }}></span>
        <span className="text-muted fw-bold">{name}</span>
      </div>
      {selectedId === user_id && (
        <div>
          <IoCheckmarkSharp className='h4 text-muted ' />
        </div>
      )}
    </div>
  )
}
import TaskApi from 'apis/TaskApi';
import useAuth from 'hooks/useAuth';
import { useState, useEffect } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { BsBriefcase } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import { updateEvent } from 'store/trigger';
import {  BiTask } from 'react-icons/bi';
import { RiTaskFill } from 'react-icons/ri';

function Sidebar() {
  const [taskCount, setTaskCount] = useState(0);
  const [ticketCount, setTicketCount] = useState(0);
  const triggerEvent = useSelector(state => state.trigger);
  const dispatch = useDispatch();

  const linkCount = async () => {
    const { data } = await TaskApi.count();
    setTaskCount(data.task || '');
    setTicketCount(data.ticket || '');
  };

  useEffect(() => {
    linkCount();
  }, []);

  return (
    <div className="sidebar">
      <ul className='menu'>
        <li>
          <NavLink to="dashboard">
            <AiOutlineClockCircle />
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li>
          <TaskLink count={taskCount} />
        </li>
        <li>
          <TicketLink count={ticketCount} />
        </li>
      </ul>
    </div>
  );
}
  
export default Sidebar;

function TicketLink({ count }) {
  const auth = useAuth();

  function Count() {
    if (count > 0)
      return <span className='ms-auto linkCount'>{count}</span>;
    return '';
  }

  if (auth && auth.is_admin) {
    return (
      <NavLink to="ticket">
        <RiTaskFill />
        <span>Tickets</span>
        <Count />
      </NavLink>
    );
  }

  return (
    <NavLink to="ticket">
      <RiTaskFill />
      <span>Your Tickets</span>
      <Count />
    </NavLink>
  )
}

function TaskLink({ count }) {
  const auth = useAuth();

  function Count() {
    if (count > 0)
      return <span className='ms-auto linkCount'>{count}</span>;
    return '';
  }

  if (auth && auth.is_admin) {
    return (
      <NavLink to="task">
        <BiTask />
        <span>Tasks</span>
        <Count />
      </NavLink>
    );
  }

  return (
    <NavLink to="task">
      <BiTask />
      <span>Your Tasks</span>
      <Count />
    </NavLink>
  );
}

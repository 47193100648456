import axios from 'axios';
// import { toast } from 'react-hot-toast';

export const baseURL = (
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  ) ? (
    'http://localhost/hotwork-v3/'
  ) : (
    'https://app.hotwork.ag/'
  );

export const http = axios.create({
  baseURL: baseURL + 'api/',
  headers: { 
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
  }
});

// let alertCount = 0;

// http.interceptors.response.use(
//   config => config,
//   async error => {
//     alertCount += 1;
//     if (alertCount === 1) {
//       toast.error('Oops! Something went wrong. Please reload the page and try again.', {
//         position: 'top-center',
//         duration: 3000,
//         style: {
//           background: 'var(--bs-danger)',
//           color: '#fff'
//         }
//       })
//     }
//     return Promise.reject(error);
//   })
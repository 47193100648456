import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Toaster } from 'react-hot-toast';

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-quill/dist/quill.snow.css';
import './index.css';
import store from './store';
import { channel } from 'helpers';
import { updateEvent } from 'store/trigger';

// window.Pusher.logToConsole = true;

// channel.bind("notify", (data) => {
//   store.dispatch( updateEvent({ state: 'notifyEvent', value: data.notify_to }) );
// });

// channel.bind("list-count", (data) => {
//   store.dispatch( updateEvent({ state: 'listCount', value: 'list-count' }) )
// });

// channel.bind("updated-task", (data) => {
//   store.dispatch( updateEvent({ state: 'updatedTask', value: 'updated-task' }) );
// });

// channel.bind("read-task", (data) => {
//   console.log('read task');
// });

// channel.bind("created-task", (data) => {
//   store.dispatch( updateEvent({ state: 'createdTask', value: 'created-task' }) )
// });

// channel.bind("remark-updated", (data) => {
//   store.dispatch( updateEvent({ state: 'remarkUpdated', value: 'remark-updated' }) )
// });

channel.bind("ticket", (data) => {
  if (data && data.trigger_to) {
    // console.log(data)
    store.dispatch( 
      updateEvent({ 
        state: data.redux_state, 
        value: true,
        triggered_by: data.auth_id
      })
    );
  }
});

const root = ReactDOM.createRoot(document.getElementById('app-root'));

root.render(
  <React.StrictMode>
    <>
      <App />
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();

reportWebVitals();

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './project.css';
import { useState } from 'react';
import ProjectApi from 'apis/ProjectApi';

function CreateProject({ onClose, onSubmit }) {
  const [name, setName] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);

  const handleSubmit = async () => {
    setDisabledButton(true);
    const { data } = await ProjectApi.create({
      employee_task_project_name: name
    });
    if (data.success) {
      onSubmit && onSubmit();
      setDisabledButton(false);
    }
  };

  return (
    <>
      <Modal.Header closeButton={!disabledButton}>
        <Modal.Title as="h5">Create Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label className="mb-2">NAME</label>
          <input placeholder="Project Name" className="form-control" 
            value={name || ''}
            onChange={e => setName(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light fw-bold" 
          onClick={onClose} 
          disabled={disabledButton}
        >
          Close
        </Button>
        <Button variant="primary app fw-bold" 
          onClick={handleSubmit} 
          disabled={disabledButton || !name}
        >
          Save
        </Button>
      </Modal.Footer>
    </>
  );
}

export default CreateProject;
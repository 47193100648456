import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  triggerList: false,
}

export const taskSlice = createSlice({
  initialState,
  name: 'newTask',
  reducers: {
    updateTriggerList(state, action) {
      state.triggerList = action.payload;
    },
  },
});

export const { updateTriggerList } = taskSlice.actions;

export default taskSlice.reducer;
import PrivateRoute from "components/PrivateRoute";
import { Route, Routes, Outlet } from "react-router-dom";
import List from './List';

function Project() {
  return (
    <PrivateRoute>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<List />} />
        </Route>
      </Routes>
    </PrivateRoute>
  );
}

export default Project;
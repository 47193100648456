import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  globalLoading: false,
}

export const loadingSlice = createSlice({
  initialState,
  name: 'loading',
  reducers: {
    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },
  },
});

export const { setGlobalLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
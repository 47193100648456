import './login.css';
import Select from 'react-select';
import { companies } from 'helpers';
import { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { baseURL } from 'helpers/http';
import { Navigate, useSearchParams } from 'react-router-dom';

function Login() {
  const [company, setCompany] = useState(1);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [buttonText, setButtonText] = useState('Log in');
  const [searchParams] = useSearchParams();

  const onLogin = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    
    formData.append('company_id', company);
    formData.append('email', username);
    formData.append('password', password);
    const { data } = await axios.post(baseURL + 'api/mobile/auth/login', formData);
    const ts = new FormData();
    ts.append('token', data.token);

    if (data && data.success) {
      setButtonText('Logging in...');
      setTimeout(() => {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        window.dispatchEvent(new Event('storage'));
      }, 1000);
    } else {
      toast.error(data.message, { position: 'bottom-center' });
    }
  };

  if (localStorage.getItem('token')) {
    window.location.href = searchParams.get('next') || '/dashboard';
  }

  return (
    <div className='login-wrapper'>
      <div className="bg-white p-5">
        <div className="login-logo d-flex align-items-center mb-4 justify-content-center">
          <img src={require('images/hwi_logo.png')} alt="Hotware Logo" />
          <h4 className='text-muted mb-0'>HWI Ticketing</h4>
        </div>
        <form  className="form-wrapper" onSubmit={onLogin}>
          <div className="mb-4">
            <label className='mb-2'>COMPANY</label>
            <Select 
              placeholder="Company"
              options={companies}
              className="form-select-tag"
              onChange={option => setCompany(option.value)}
              value={companies.find(item => item.value === company)}
            />
          </div>
          <div className="mb-4">
            <label className='mb-2'>EMAIL</label>
            <input placeholder="Email" className="form-control" 
              value={username || ''}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className='mb-2'>PASSWORD</label>
            <input placeholder="Password" className="form-control" 
              type="password"
              value={password || ''}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary w-100 fw-bold app" 
            disabled={!password || !username}
          >
            {buttonText}
          </button>
        </form>
      </div>
    </div>
  );
}



export default Login;
import Swal from 'sweetalert2';

export const alert = async ({ title = 'Are you sure?', text = "You won't be able to revert this!" }) => {
  return await Swal.fire({
    title,
    text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
    showClass: {
      popup: 'animate__animated animate__slideInDown'
    },
    hideClass: {
      popup: 'animate__animated animate__slideOutDown'
    }
  });
};
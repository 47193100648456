import { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { v1 } from "uuid";
import { TodoItem } from "./TodoItem";

export default function Todos({ todos, onChangeTodos }) {
  const [todoInput, setTodoInput] = useState(null);

  const onAddTodo = (e) => {
    e.preventDefault();
    const _todos = [...todos];
    _todos.push({ name: todoInput, id: v1() });
    onChangeTodos && onChangeTodos(_todos);
    setTodoInput(null);
  };

  const handleTodoEdit = (index, value) => {
    const _todos = [...todos];
    _todos[index].name = value;
    onChangeTodos && onChangeTodos(_todos);
  };

  const onDeleteTodo = (index) => {
    const _todos = [...todos];
    _todos.splice(index, 1);
    onChangeTodos && onChangeTodos(_todos);
  }

  return (
    <>
      <div className='todoAddForm'>
        <form className="form-control" onSubmit={onAddTodo}>
          <input placeholder="Add Todo" 
            value={todoInput || ''}
            onChange={(e) => setTodoInput(e.target.value)}
          />
          <button 
            className="btn btn-warning fw-bold"
            disabled={!todoInput || todoInput === ''}
          >
            Add
          </button>
        </form>
      </div>
      <ul className="todo_list">
        <TransitionGroup>
          {todos && todos.map((item, index) => (
            <CSSTransition
              key={item.id}
              nodeRef={item.nodeRef}
              timeout={500}
              classNames="todo-items"
            >
              <TodoItem 
                ref={item.nodeRef}
                handleTodoEdit={e => handleTodoEdit(index, e.target.value)}
                onDeleteTodo={() => onDeleteTodo(index)}
                value={item.name}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </ul>
    </>
  )
}
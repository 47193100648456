import { http } from 'helpers/http';

const base_url = 'ticketing/ticket';
export default class TaskApi 
{
  static async paginate(params = {  }) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(params)) {
      formData.append(key, value);
    } 
    const resp = await http.post(base_url + '/list', formData);
    return resp;
  } 

  static async count(params = {  }) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(params)) {
      formData.append(key, value);
    }
    const resp = await http.post(base_url + '/count', formData);
    return resp;
  } 

  static async dashboard_count(params = {}) {
    const resp = await http.get(base_url + '/dashboard_count', { params });
    return resp;
  }

  static async get(taskId) {
    const resp = await http.get(base_url + '/get_task/' + taskId);
    return resp;
  }

  static async updateTask(taskId, data) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const resp = await http.post(base_url + '/update_task/' + taskId, formData);
    return resp;
  }

  static async createTask(data) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const resp = await http.post(base_url + '/create_task', formData);
    return resp;
  }

  static async updateTodo(todoId, data) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const resp = await http.post(base_url + '/update_todo/' + todoId, formData);
    return resp;
  }

  static async addTodo(data) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const resp = await http.post(base_url + '/create_todo', formData);
    return resp;
  }

  static async deleteTodo(todo_id) {
    const formData = new FormData();
    formData.append('employee_task_todo_id', todo_id);
    const resp = await http.post(base_url + '/delete_todo', formData);
    return resp;
  }

  static async deleteTask(id) {
    const formData = new FormData();
    formData.append('employee_task_id', id);
    const resp = await http.post(base_url + '/delete', formData);
    return resp;
  }

  static async listTask(status, params = {}) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(params)) {
      formData.append(key, value);
    }
    const resp = await http.post(base_url + '/list_task/' + status, formData);
    return resp;
  }

  static async listTicket(status, params = {}) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(params)) {
      formData.append(key, value);
    }
    const resp = await http.post(base_url + '/list_ticket/' + status, formData);
    return resp;
  }

  static async requestSuccess(params = {}) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(params)) {
      formData.append(key, value);
    }
    const resp = await http.post(base_url + '/request_success', formData);
    return resp;
  }

  static async testEmail() {
    const resp = await http.get(base_url + '/test_email');
    return resp;
  }
  
}
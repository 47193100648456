import axios from 'axios';
import { baseURL } from './http';

const urlParams = new URLSearchParams(window.location.search);
const username = urlParams.get('username');
const fromHotware = urlParams.get('from_hotware');
const company = urlParams.get('company_id');

export default async function() {
  if (localStorage.token) {
    window.history.pushState({}, document.title, window.location.pathname);
    return;
  }

  if (!fromHotware || !username || !company) {
    return;
  }

  const formData = new FormData();
  formData.append('from_hotware', fromHotware);
  formData.append('company_id', company);
  formData.append('email', username);
  const { data } = await axios.post(baseURL + 'api/mobile/auth/login', formData);

  if (data && data.success) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
    window.dispatchEvent(new Event('storage'));
    window.location.href = urlParams.get('next') || '/dashboard';
  }
};
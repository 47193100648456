import { Route, Routes, Outlet } from "react-router-dom";
import List from './List';
import ViewTaskFinal from 'components/ViewTaskFinal';
import PrivateRoute from 'components/PrivateRoute';

function Task() {
  return (
    <PrivateRoute>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<List />} />
          <Route path=":taskId" element={<ViewTaskFinal />} />
        </Route>
      </Routes>
    </PrivateRoute>
  );
}

export default Task;
import { BsPlusLg } from 'react-icons/bs';
import Modal from 'react-bootstrap/Modal';
import CreateProject from './Create';
import { useEffect, useState, useCallback } from 'react';
import toast from 'react-hot-toast';
import ProjectApi from 'apis/ProjectApi';
import Pagination from 'components/Pagination';
import { Link, useSearchParams } from 'react-router-dom';

function List() {
  const [openProjectModal, setOpenProjectModal] = useState(false);
  const [projects, setProjects] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');

  const getProjects = useCallback(async (page) => {
    const { data } = await ProjectApi.paginate({ page });
    if (data) {
      const { lists, pagination } = data;
      setProjects(lists);
      setPagination(pagination);
    }
  }, []);

  const onSubmitCreateProject = () => {
    toast.success('Successfully created!');
    setTimeout(() => {
      setOpenProjectModal(false);
      getProjects();
    }, 100);
  };

  const onPaginate = (page) => {
    setSearchParams({ page });
  }

  useEffect(() => {
    getProjects(page);
  }, [page, getProjects]);

  return (
    <>
      <div className="box col-md-10">
        <div className="d-flex justify-content-between mb-3">
          <h4>Projects</h4>
          <button className="btn btn-primary fw-bold app" 
            onClick={() => setOpenProjectModal(true)}
          >
            <BsPlusLg /> 
            <span className="ms-2">Add Project</span>
          </button>
        </div>

        <table className='table mb-0'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Created By</th>
              <th align="right">Used Count</th>
              <th align="right"></th>
            </tr>
          </thead>
          <tbody>
            {projects && projects.map((project, key) => (
              <tr key={key}>
                <td>
                  <span className="fw-bold d-flex align-items-center">
                    <span className='project-circle' 
                      style={{ backgroundColor: project.employee_task_project_color }} 
                    />
                    {project.employee_task_project_name}
                  </span>
                </td>
                <td>
                <span className="fw-bold">
                  {project.user_id === null ? 'Default' : (
                    project.user_firstname + ' ' + project.user_lastname
                  )}
                </span>
                </td>
                <td>
                  {(project.used_count).toLocaleString()} times
                </td>
                <td>
                  {Number(project.used_count) > 0 && (
                    <Link to={`/task?project_id=${project.employee_task_project_id}`}>View task</Link>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {pagination && (
          <div className="d-flex justify-content-end pt-4">
            <Pagination 
              html={pagination}
              onPaginate={onPaginate}
            />
          </div>
        )}
      </div>

      <Modal 
        show={openProjectModal} 
        onHide={() => setOpenProjectModal(false)}
        backdrop='static'
      >
        <CreateProject 
          onClose={() => setOpenProjectModal(false)}
          onSubmit={onSubmitCreateProject}
        />
      </Modal>
    </>  
  );
}

export default List;
import TaskApi from 'apis/TaskApi';
import PrivateRoute from 'components/PrivateRoute';
import dayjs from 'dayjs';
import { priorityColor, statusColor } from 'helpers';
import useAuth from 'hooks/useAuth';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './ongoing-task.css';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { NotificationContent } from 'Layout/Header';

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);
dayjs.extend(utc);

function dueDateRelativeTime(date) {
  if (dayjs(date).isToday()) {
    return 'Today';
  }
  if (dayjs(date).isYesterday()) {
    return 'Yesterday';
  }
  if (dayjs(date).isTomorrow()) {
    return 'Tomorrow';
  }
  return dayjs(date).format('MMM DD, YYYY');
}

function Dashboard() {
  const auth = useAuth();

  return (
    <PrivateRoute>
      <div className="container-fluid">
        <div className="dashboardContainer">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-4">
                <h3 className='mb-2'>
                  <span className='text-muted' style={{ fontWeight: 900 }}>{dateTime()}</span>
                  {/* <span style={{ fontWeight: 900 }}> {auth && auth.firstname} 😃</span> */}
                </h3>
              </div>
              {/* <TickDate /> */}
              <NotificationContent />
              {/* <div className="row">
                <div className="col-md-6">
                  <DashboardCount type="task" className='first' />
                </div>
                <div className="col-md-6">
                  <DashboardCount type="ticket" className='second'  />
                </div>
              </div> */}
            </div>
            <div className="col-md-6">
              <div className="mb-4">
                <h3 className='mb-2'>
                  <span className='text-muted' style={{ fontWeight: 900 ,visibility: 'hidden' }}>{dateTime()}</span>
                  {/* <span style={{ fontWeight: 900 }}> {auth && auth.firstname} 😃</span> */}
                </h3>
              </div>
              <div className="TaskContent p-0">
                <div className='TaskContentBordered'>
                  <DueToday title={'Tasks'} type='task' />
                  {/* <DueToday title={'Tickets'} type='ticket' /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrivateRoute>
  );
}

function DashboardCount({ className, type }) {
  const [taskCount, setTaskCount] = useState(0);
  const [ticketCount, setTicketCount] = useState(0);

  const getCounts = useCallback(async () => {
    const payload = {};
    if (type === 'ticket') payload['is_ticket'] = true;
    const { data } = await TaskApi.dashboard_count(payload);
    setTaskCount(data.task_count);
    setTicketCount(data.ticket_count);
  }, [type]);

  useEffect(() => {
    getCounts();
  }, [getCounts]);

  return (
    <>
      <div className={`dashboardCount ${className}`}>
        <div className=''>
          <span className='headingText'>
            Finished {type === 'ticket' ? 'Ticket' : 'Task'}
          </span>
          <p className='mb-0 mt-4'>{(type === 'ticket' ? ticketCount : taskCount).toLocaleString()}</p>
        </div>
      </div>
    </>
  )
}

function DueToday({ title, type }) {
  const [tasks, setTasks] = useState(null);
  const navigate = useNavigate();

  const getTasks = useCallback(async () => {
    const payload = { 
      per_page: 10, 
      due_today: true, 
    };
    if (type === 'ticket') {
      payload['is_ticket'] = true;
    }
    const { data } = await TaskApi.paginate(payload);
    setTasks(data);
  }, [type]);

  const onClickTask = (task_id) => {
    navigate(
      type === 'task' ? '/task/' + task_id : '/ticket/' + task_id
    );
  };

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  return (
    <div className='p-2' style={{ minHeight: 'calc(var(--min-height) / 2)' }}>
      <div className='d-flex justify-content-between align-items-center mb-3 pb-2 px-3 border-bottom'>
        <p className='mb-0 text-muted fw-bold' style={{fontSize: '1.5em'}}>Ticket Lists</p>
        {tasks && tasks.lists.length > 0 && (
          <Link className="btn btn-light fw-bold btn-sm text-muted" 
            to={type === 'task' ? '/task/' : '/ticket/'}
          >
            View All
          </Link>
        )}
      </div>
      {tasks && (
        <table className="table DashboardTable">
          <tbody>
            {tasks.lists.length > 0 ? (
              <tr>
                <td className='text-muted border-0' style={{ fontSize: '0.9em' }}>Task Name</td>
                <td className='text-muted  border-0' style={{ fontSize: '0.9em' }}>Due Date</td>
                <td className='text-muted  border-0' style={{ fontSize: '0.9em' }}>Priority Level</td>
                {/* <td className='text-muted  border-0' style={{ fontSize: '0.9em' }}>Project</td> */}
                <td className='text-muted  border-0' style={{ fontSize: '0.9em' }}>Status</td>
              </tr>
            ) : (
              <tr >
                <td colSpan={4} className="border-0">
                  <div className='noTaskTodayDiv text-muted'>
                    Due-date {type === 'task' ? 'Tasks' : 'Tickets'} starting today will appear here.
                  </div>
                </td>
              </tr>
            )}
            {tasks.lists.map((item, key) => (
              <tr key={key} role="button" onClick={() => onClickTask(item.employee_task_id)}
                className='taskListRow'
              >
                <td className='fw-bold border-0  py-3' 
                  style={{ verticalAlign: 'middle' }}
                >
                  {item.employee_task_name}
                </td>
                <td className="fw-bold border-0"
                  style={{ verticalAlign: 'middle' }}
                >
                  {dueDateRelativeTime(item.employee_task_due_date)}
                </td>
                <td className='border-0'
                  style={{ verticalAlign: 'middle' }}
                >
                  <span className="DashboardTaskPriority text-uppercase" 
                    style={{ '--color': priorityColor[item.employee_task_priority_level] }}
                  >
                    {item.employee_task_priority_level}
                  </span>
                </td>
                {/* <td className='border-0'
                  style={{ verticalAlign: 'middle' }}
                >
                  <span 
                    className='DashboardTaskProject' 
                    style={{ '--color': item.employee_task_project_color }}
                  >
                    <div className="__dot" />
                    {item.employee_task_project_name}
                  </span>
                </td> */}
                <td className='border-0'
                  style={{ verticalAlign: 'middle' }}
                >
                  <span className="StatusList text-capitalize" 
                    style={{ '--color': statusColor[item.employee_task_status] }}
                  >
                    {item.employee_task_status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

function TickDate() {
  const [currTime, setCurrTime] = useState(new Date());
  
  useEffect(() => {
    const timeId = setInterval(() => {
      setCurrTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timeId);
    }
  }, []);

  return (
    <div className='tickDateDashboard'>
      <div className='tickDateDashboardContent'>
        <span>
          Today is, {dayjs.utc(currTime).format('dddd MMMM DD YYYY')} {new Date().toLocaleTimeString()}
        </span>
      </div>
    </div>
  )
}

function dateTime() {
  var ndate = new Date();
  var hours = ndate.getHours();
  var message = hours < 12 ? 'Good Morning' : hours < 18 ? 'Good Afternoon' : 'Good Evening';
  return message;
}

export default Dashboard;
import TaskApi from 'apis/TaskApi';
import React, { useState } from 'react';
import useAuth from 'hooks/useAuth';
import toast from 'react-hot-toast';
import { CLOSED } from 'helpers';
import { BsTrash } from 'react-icons/bs';

export default function Todos({ todos, task_id, canModify, task, newTodo, onUpdate }) {
  const [todoInput, setTodoInput] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const onAddTodo = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    const { data } = await TaskApi.addTodo({
      employee_task_id: task_id,
      employee_task_todo_name: todoInput,
    });
    if (data.success) {
      setSubmitted(false);
      setTodoInput(null);
      newTodo && newTodo(data.todo);
      await TaskApi.requestSuccess({
        request_type: 'create_todo',
        employee_task_id: task_id,
      });
      onUpdate && onUpdate(true);
    }
  };

  const onDeleteTodo = async (todo_id) => {
    const { data } = await TaskApi.deleteTodo(todo_id);
    if (data && data.success) {
      onUpdate && onUpdate(true);
    }
  };

  return (
    <>
      <p className="my-2 text-muted">Task Todo's</p>
      {task && task.can_modify.todos && (
        <div className='todoAddForm'>
          <form onSubmit={onAddTodo} className="form-control">
            <textarea placeholder="Add Todo" 
              value={todoInput || ''}
              onInput={({ target: { parentNode, value } }) => {
                parentNode.dataset.replicatedValue = value;
              }}
              onChange={(e) => setTodoInput(e.target.value)}
            />
            <button 
              className="btn btn-warning fw-bold"
              disabled={!todoInput || todoInput === '' || submitted}
            >
              Add
            </button>
          </form>
        </div>
      )}
      <div className="user-todo-wrapper">
        <div className='overflow-hidden'>
          {todos && todos.length === 0 && (
            <p className="py-3 text-center text-muted small">No Todos added.</p>
          )}
          {todos && todos.map((todo, indx) => (
            <div 
              key={indx}
              ref={todo.nodeRef}
              className={`todo--wrapper d-flex ${todo.employee_task_todo_completed === '1' ? 'completed': ''}`} 
            >
              {canModify.checkTodo ? (
                <EditableTodoItemCheckButton todo={todo} task_id={task_id} onUpdate={onUpdate} />
              ) : (
                <TodoItemCheckButton todo={todo} task={task} />
              )}
              <span className="todo-text ms-2">
                {todo && todo.employee_task_todo_name}
              </span>
              {todo.can_delete && (
                <span 
                  className='ms-auto clickTrash' 
                  role="button" 
                  tabIndex={0} 
                  title="Delete" 
                  onClick={() => onDeleteTodo(todo.employee_task_todo_id)}
                >
                  <BsTrash />
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function EditableTodoItemCheckButton({ todo, task_id, onUpdate }) {

  const onCheckTodo = async (e, todoId, todoStatus) => {
    const employee_task_todo_completed = Number(todoStatus) === 1 ? 0 : 1;

    const payload = { employee_task_todo_completed, employee_task_id: task_id };

    if (Number(todoStatus) === 0) {
      e.currentTarget?.classList.add('checked');
      e.currentTarget?.parentElement.classList.add('completed');
    } else {
      e.currentTarget.classList?.remove('checked');
      e.currentTarget?.parentElement.classList.remove('completed');
    }
  
    const { data } = await TaskApi.updateTodo(todoId, { ...payload });

    if (data.success) {
      await TaskApi.requestSuccess({
        request_type: 'update_todo',
        employee_task_id: task_id,
      });
      onUpdate && onUpdate(true);
    }
  }

  return (
    <div  
      className={`checkmark-circle d-flex align-items-center ${todo.employee_task_todo_completed === '1' ? 'checked' : ''}`} 
      tabIndex={0} 
      onClick={(e) => onCheckTodo(e, todo.employee_task_todo_id, todo.employee_task_todo_completed)}
    >
      <div className="checkmark" />
    </div>
  );
}

function TodoItemCheckButton({ todo, task }) {
  const auth = useAuth();

  const onCheckTodo = () => {
    if (task) {
      if (auth.id === task.employee_task_assigned_to && task.employee_task_status === CLOSED) {
        return;
      }
      toast.error('Action Not Authorized!', {
        position: 'top-center'
      });
    }
  }
  return (
    <div  
      className={`checkmark-circle d-flex align-items-center ${todo.employee_task_todo_completed === '1' ? 'checked' : ''}`} 
      tabIndex={0} 
      onClick={onCheckTodo}
    >
      <div className="checkmark" />
    </div>
  );
}
import { useNavigate } from 'react-router-dom';
import './404.css';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className='Four0Four'>
      <h1>Oops!</h1>
      <h4>404 - PAGE NOT FOUND</h4>
      <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
      <button className='goHomeButton'
        onClick={() => navigate('/dashboard')}
      >
        Go to Homepage
      </button>
    </div>
  )
}
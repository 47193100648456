import TaskApi from "apis/TaskApi";
import { priorityColor, priorityLevels, setLoading } from "helpers";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";

export default function EditablePriorityLevel({ task, onUpdate }) {
  const [showModal, setShowModal] = useState(false);

  const onSelectPriorityLevels = async (priorityLevel) => {
    if (task.employee_task_priority_level !== priorityLevel) {
      setLoading(true);
      const { data } = await TaskApi.updateTask(task.employee_task_id, { employee_task_priority_level: priorityLevel });
      if (data.success) {
        setLoading(false);
        toast.success('👍 Successfully Updated.', {
          position: 'top-center'
        });
        onUpdate && onUpdate(true);
        await TaskApi.requestSuccess({
          employee_task_priority_level: priorityLevel,
          request_type: 'employee_task_priority_level',
          employee_task_id: task.employee_task_id,
        });
      }
      setShowModal(false);
    }
  };

  const onOpenModal = (ev) => {
    if (ev.target.nodeName === 'SPAN' && ev.target.classList.contains('remove-priority')) {
      onSelectPriorityLevels(null);
      return;
    }
    setShowModal(true);
  }

  useEffect(() => {
    if (showModal) {
      document
        .querySelector('.modal-prio_levels-backdrop')
        .style.setProperty('--bs-backdrop-zindex', 1055);
    }
  }, [showModal]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdropClassName="modal-prio_levels-backdrop"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5" id="contained-modal-title-vcenter">
            Choose Priority Levels
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {priorityLevels.map((item, key) => (
            <div 
              key={key} 
              className={`
                user-view_PriortyLevel creatorView select 
                ${item.value === task.employee_task_priority_level ? 'active' : ''}
              `} 
              style={{ '--color': item.color }} 
              tabIndex={0}
              role="button"
              onClick={() => onSelectPriorityLevels(item.value)}
            >
              <div className="_dot" />
              <span>{item.text}</span>
            </div>
          ))}
        </Modal.Body>
      </Modal>

      <div 
        className={`user-view_PriortyLevel creatorView text-uppercase canEdit`} 
        style={{ '--color': priorityColor[task.employee_task_priority_level] || '10, 10, 10' }}
        tabIndex={0}
        onClick={onOpenModal}
        role={'button'}
      >
        <div className="d-flex justify-content-between w-100 align-items-center">
          {task.employee_task_priority_level || <span className="text-capitalize fw-normal">No priority</span>}
          {task.employee_task_priority_level && (
            <span tabIndex={0} className="fw-bold fs-5 remove-priority text-muted" title="Remove">
            &times;
          </span>
          )}
        </div>
      </div>
    </>
  );
}
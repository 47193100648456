import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  updated_task: false,
  updated_remarks: false,
  updated_notifs: false,
  updated_task_count: false,
  triggered_by: null,
}

export const triggerSlice = createSlice({
  initialState,
  name: 'trigger',
  reducers: {
    updateEvent: (state, action) => {
      state[action.payload.state] = action.payload.value;
      state.triggered_by = action.payload.triggered_by;
    },
  },
});

export const { updateEvent } = triggerSlice.actions;

export default triggerSlice.reducer;
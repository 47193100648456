import useAuth from "hooks/useAuth";

export default function AssignedTo({ task }) {
  const auth = useAuth();

  const { 
    assigned_to_user_id: id, 
    assigned_to_user_photo: photo, 
    assigned_to_user_firstname,
    assigned_to_user_lastname, 
    employee_task_is_accepted: isAccepted 
  } = task;

  function Wrapper({ hasPhoto, name, subText }) {
    return (
      <div className='assignedTo-user-view' style={{ '--user-photo': hasPhoto && `url(${photo})` }}>
        {hasPhoto && <div className="photo-user-view" />}
        <span> {name} {subText}</span>
      </div>
    )
  }

  if (!id) {
    return (
      <div className='assignedTo-user-view text-muted'>
        Not Yet Assigned
      </div>
    );
  }

  // If Admin
  if (auth && auth.is_admin) {
    if (isAccepted === null) {
      return (
        <Wrapper 
          hasPhoto={true}
          name={assigned_to_user_firstname + ' ' + assigned_to_user_lastname}
          subText={<span className="small text-warning">Pending</span>}
        />
      );
    }

    if (isAccepted === true) {
      return (
        <Wrapper 
          hasPhoto={true}
          name={assigned_to_user_firstname + ' ' + assigned_to_user_lastname}
          subText={<span className="small text-secondary">Assigned</span>}
        />
      );
    }

    if (isAccepted === false) {
      return (
        <Wrapper 
          hasPhoto={true}
          name={assigned_to_user_firstname + ' ' + assigned_to_user_lastname}
          subText={isAccepted === false && id && <span className="text-danger small">Rejected</span>}
        />
      );
    }
  }

  // If Creator or Other User
  if (auth && (id !== auth.id)) {
    if (isAccepted === true) {
      return (
        <Wrapper 
          hasPhoto={false}
          name={'Hidden'}
          subText={<span className="text-secondary small">(Assigned)</span>}
        />
      );
    }

    if (isAccepted === false) {
      return (
        <Wrapper 
          hasPhoto={false}
          name={'Hidden'}
          subText={<span className="text-danger small">(Rejected)</span>}
        />
      );
    }

    if (isAccepted === null) {
      return (
        <Wrapper 
          hasPhoto={false}
          name={'Hidden'}
          subText={<span className="text-warning small">(Pending)</span>}
        />
      );
    }
  }

  // If Assigned User
  if (auth && (id === auth.id)) {
    return (
      <Wrapper 
        hasPhoto={true}
        name={assigned_to_user_firstname + ' ' + assigned_to_user_lastname}
        subText={<>
          {isAccepted === true && <span className="text-secondary small">(Assigned)</span>}
          {isAccepted === false && <span className="text-danger small">(Rejected)</span>}
          {isAccepted === null && <span className="text-warning small">(Assigned)</span>}
        </>}
      />
    );
  }
}

import TaskApi from 'apis/TaskApi';
import _ from 'lodash';
import { useCallback } from 'react';

export default function TaskTitle({ task }) {
  return <h4>{task.employee_task_name}</h4>
}

export function EditableTaskTitle({ task }) {
  
  const saveData = _.debounce(async (_name) => {
    await TaskApi.updateTask(task.employee_task_id, {
      employee_task_name: _name,
    });
  }, 1000)

  const updateTaskName = useCallback((name) => saveData(name), []);

  const onChange = (e) => {
    updateTaskName(e.target.value);
  };

  return (
    <div className="editableTaskName d-flex align-items-center">
      <span className="me-2">✎</span>
      <input 
        defaultValue={task.employee_task_name} 
        onChange={onChange}
        placeholder="Task Name"
      />
    </div>
  )
}
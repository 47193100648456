import RemarkApi from "apis/RemarkApi";
import Pagination from "components/Pagination";
import { useCallback, useEffect, useState } from "react";
import { MdClose, MdOutlineAttachment } from "react-icons/md";
import RemarkList from './RemarkList';
import useRealtime from "hooks/useRealtime";

export default function Remarks({ task_id, canModify, task, isUpdated, onUpdate }) {
  const [remarkText, setRemarkText] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [remark, setRemark] = useState(null);
  const [emptyList, setEmptyList] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [attachedFiles, setAttachedFiles] = useState(null);
  const isRLupdated = useRealtime('updated_remarks');

  const getRemarks = useCallback(async (page = 1) => {
    const { data } = await RemarkApi.paginate(task_id, { page });
    setRemark(data);
    setPagination(data.pagination !== '' ? data.pagination : null);
    if (Array.isArray(data.lists) && data.lists.length === 0) {
      setEmptyList(true);
    } else {
      setEmptyList(false);
    }
  }, [task_id]);

  const onPaginate = (page) => {
    getRemarks(page);
  }

  const getRemarksData = useCallback(async () => {
    await getRemarks();
  }, [getRemarks]);

  const onAddRemark = async () => {

    if (!remarkText && (Array.isArray(attachedFiles) && attachedFiles.length === 0)) {
      return;
    }

    setSubmitting(true);
    const payload = {
      message: remarkText || '',
      employee_task_id: task_id,
    };
    if (attachedFiles) {
      attachedFiles.forEach((file, key) => {
        payload[`attached_files[${key}]`] = file;
      });
    }
    const { data } = await RemarkApi.create(payload);
    if (data.success) {
      setSubmitting(false);
      setRemarkText(null);
      setAttachedFiles(null);
      getRemarksData();
      onUpdate && onUpdate(true);
      await RemarkApi.requestSuccess({
        employee_task_id: task_id,
      });
    }
  };

  const onChangeCommentFile = (e) => {
    setAttachedFiles([...e.target.files]);
  };

  const deleteAttachedFile = (index) => {
    const _attachedFiles = [...attachedFiles];
    setAttachedFiles(
      _attachedFiles.filter((item, key) => key !== index)
    );
  };

  useEffect(() => {
    getRemarksData();
  }, [getRemarksData, isUpdated]);

  useEffect(() => {
    if (isRLupdated) {
      getRemarksData();
    }
  }, [isRLupdated, getRemarksData]);

  return (
    <>
      <p className="my-2 px-3 text-muted">Remarks</p>
      {task && task.can_modify.remarks && (
        <div className="ps-3 pe-2">
          <div className="user-remarks_ form-control position-relative">
            <textarea 
              onInput={({ target: { parentNode, value } }) => {
                parentNode.dataset.replicatedValue = value;
              }}
              placeholder="Add Remarks here..."
              onChange={({ target }) => setRemarkText(target.value)}
              value={remarkText || ''}
            />
            <button type="button" 
              className='btn btn-warning add-button fw-bold'
              onClick={onAddRemark}
              disabled={submitting}
            >
              Add
            </button>
            <div className="attchmentData">
              <label htmlFor="attachComment">
                <input type="file" className="d-none" id="attachComment" 
                  onChange={onChangeCommentFile}
                  multiple
                />
                <MdOutlineAttachment />
              </label>
              {attachedFiles && attachedFiles.map((item, key) => (
                <div className="attchedFiles" key={key}>
                  {item.name}
                  <button className="deleteAttachButton" type="button"
                    onClick={() => deleteAttachedFile(key)}
                  >
                    <MdClose />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      
      <div className="remarkContent d-flex flex-column mt-3">
        {/* {loading && (
          <p className="py-3 text-center text-muted small">Loading...</p>
        )} */}

        {emptyList && (
          <p className="py-3 text-center text-muted small">No Remarks added.</p>
        )}

        {remark && remark.lists && remark.lists.map((item, index) => (
          <RemarkList 
            key={index}
            task={task}
            createdBy={item.created_by}
            name={item.user_firstname + ' ' + item.user_lastname}
            photo={item.user_photo}
            message={item.message}
            is_admin={item.is_admin}
            attachments={item.attachments}
            remark_type={item.remark_type}
          />
        ))}

      {pagination && (
        <div 
          className='p-2 text-muted mb-1 mt-0 d-flex justify-content-end border-top mt-auto'
        >
          <Pagination 
            html={pagination} 
            onPaginate={onPaginate} 
          />
        </div>
      )}
      </div>
    </>
  );
}
import { PENDING } from 'helpers';
import { useEffect, useState } from 'react';

export default function useAuth() {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getToken = () => {
      const token = localStorage.getItem('token');
      const user = localStorage.getItem('user');
      const parsedUser = JSON.parse(user);
      setUser(parsedUser);
      setToken(token);
    };
    getToken();
    window.addEventListener('storage', getToken);
    return () => {
      window.removeEventListener('storage', getToken);
    };
  }, []);

  return {
    token,
    ...user,
  }
}

export const canUpdateTask = (isAdmin, taskStatus) => {
  if (!isAdmin && taskStatus === PENDING) {
    return true;
  } 
  return false;
}
import TaskApi from "apis/TaskApi";
import { setLoading } from "helpers";
import { toast } from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import RemarkApi from "apis/RemarkApi";

export default function AssignToReviewButton({ task, onUpdate }) {
  const [openRejectRemark, setOpenRejectRemark] = useState(false);
  const { 
    assigned_to_user_photo: photo, 
    assigned_to_user_firstname,
    assigned_to_user_lastname, 
    employee_task_is_accepted: isAccepted 
  } = task;

  const onReviewTask = async (type) => {
    setLoading(true);
    const payload = { employee_task_is_accepted: type === 'accepted' ? 1 : 0 };
    const { data } = await TaskApi.updateTask(task.employee_task_id, { ...payload });
    if (data.success) {
      setLoading(false);
      if (payload.employee_task_is_accepted === 1) {
        toast.success('😀 You can now start working the task.', {
          position: 'top-center',
        });
      } 
      if (payload.employee_task_is_accepted === 0) {
        toast.error('😔 You declined the task.', {
          position: 'top-center',
        });
      }
      onUpdate && onUpdate(true);
      await TaskApi.requestSuccess({
        request_type: 'employee_task_is_accepted',
        employee_task_id: task.employee_task_id,
        employee_task_is_accepted: payload.employee_task_is_accepted,
        assigned_user_id: task.employee_task_assigned_to,
      });
    }
  };

  const onReviewedTaskDecline = async (isUpdated) => {
    onUpdate && onUpdate(isUpdated);
    if (isUpdated) {
      await onReviewTask('declined');
      setOpenRejectRemark(false);
    }
  }

  return (
    <>
      <RemarksRejectModal 
        showModal={openRejectRemark}
        onHide={() => setOpenRejectRemark(false)}
        task={task}
        onUpdate={onReviewedTaskDecline}
      />
      <div className='assignedTo-user-view flex-wrap' style={{ '--user-photo': `url(${photo})` }}>
        {(isAccepted === true || isAccepted === null) && (
          <div className="d-flex align-items-center">
            <div className="photo-user-view" />
            <span>{assigned_to_user_firstname} {assigned_to_user_lastname}</span>
          </div>
        )}
        {isAccepted === false && (
          <span className='text-danger'>You declined the task</span>
        )}
        {isAccepted === null && (
          <div className='isReviewTask ms-auto d-flex flex-wrap'>
            <button 
              className='btn btn-sm decline' 
              title="Decline the task"
              onClick={() => setOpenRejectRemark(true)}
            >
              Decline
            </button>
            <button 
              className='btn btn-sm accept ms-2' 
              title="Accept the task"
              onClick={() => onReviewTask('accepted')}
            >
              Accept
            </button>
          </div>
        )}
      </div>
    </>
  );
}

function RemarksRejectModal({ showModal, onHide, task, onUpdate }) {
  const [remarks, setRemarks] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    if (!remarks) {
      return;
    }

    setSubmitting(true);
    const payload = {
      message: remarks,
      employee_task_id: task.employee_task_id,
      remark_type: 'reject_task',
      empty_file: 1,
    };

    const { data } = await RemarkApi.create(payload);
    if (data.success) {
      setSubmitting(false);
      setRemarks(null);
      onUpdate && onUpdate(true);
      onHide();
  }
}

  return (
    <Modal
      show={showModal}
      backdropClassName="modal-project-backdrop"
      centered
    >
      <Modal.Header>
        <Modal.Title as="h5" id="contained-modal-title-vcenter">
          Reject Remarks
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <textarea 
          className="form-control"
          row={20}
          style={{ minHeight: '260px' }}
          placeholder="Enter your remarks here..."
          value={remarks || ''}
          onChange={e => setRemarks(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-light app fw-bold me-1" 
          onClick={onHide} 
          disabled={submitting}
        >
          Cancel
        </button>
        <button className="btn btn-primary app fw-bold" 
          onClick={onSubmit}
          disabled={!remarks || submitting}
        >
          {submitting ? 'Loading...' : 'Submit'}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
import './pagination.css';

const Pagination = ({ html, className, onPaginate }) => {
  
  const _onPaginate = (e) => {
    if (e.target.nodeName === 'A') {
      e.preventDefault();
      const { ciPaginationPage } = e.target.dataset;
      if (onPaginate && ciPaginationPage) {
        onPaginate(ciPaginationPage);
      }
    }
  };

  return (
    <div 
      className={`app-pagination ${className || ''}`} 
      dangerouslySetInnerHTML={{ __html: html || '' }} 
      onClick={_onPaginate}
    />
  )
};

export default Pagination;